<template>
    <div ref="panel" class="scroll-div" style="padding-top: 0;">
        <ts-table ref="table" :cols="cols" :data="list" :page-param="pageParam" :loading="loading" zero-to-empty
            :style="{ height: realHeight + 'px' }" full @handleSort="sort" :error="pageParam.error" :error-code="error"
            :name="nameKey" :summaryMethod="summaryFun" @handleRowDbClickChange="handleRowDbClickChange" hide-detail
            :height="null" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange">
            <template #searchLeft>
                <ts-search-left v-if="isSearchLeft" :searchCols="searchCols" :table-name="tableName" :cols="cols"
                    @searchClose="searchClose" @handleFilter="handleFilter"></ts-search-left>
            </template>
            <template #left>
                <ts-table-operate :cols="cols" @handleFilter="handleFilter" :parent="_self" :table-name="tableName"
                    show-export @leadingOut="exportInfo()" style="margin: 0 20px;align-items: center;"
                    @searchShowLeft="searchShowLeft">
                    <div>
                        （销售额单位：{{ $variable.unit }}）
                    </div>
                </ts-table-operate>
            </template>
        </ts-table>

        <div class="total-title__line"></div>
        <div class="total-title">
            企业销售数据图
            <div class="total-title__right">筛选：
                <el-select v-model="canvas.model" remote :remote-method="remoteMethod" value-key="id" filterable
                    style="width: 300px" @change="showProduct" :loading="canvas.loading">
                    <el-option v-for="item in canvas.list" :key="item.id" :value="item"
                        :label="item.compName"></el-option>
                </el-select>
            </div>
        </div>
        <ts-error v-if="canvas.message"></ts-error>
        <div v-else :id="nameKey" class="canvas small"></div>

        <drug-corp-pie :com-code="comCode"></drug-corp-pie>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
import DrugCorpPie from './DrugCorpPie'
export default {
    components: { DrugCorpPie },
    mixins: [myMixin],
    props: ['comCode', 'baseQuery', 'visitId'],
    data() {
        return {
            nameKey: 'newDrugCorp',
            tableName: 'sale_year',
            pageApi: 'drug/saleYear/sumAmountAndRateGroupByCompNameAndComNameByComName',
            cols: this.$help.generalCols([
                this.$variable.indexColumn.drug({
                    // listQueryModel: 1
                    listQueryView: '否',
                }),
                this.$variable.indexColumn.corp(),
                ...this.$variable.getYearCols({
                    listOrderView: '是'
                }),
            ]),
            active: '',
            canvas: {
                show: false,
                message: false,
                loading: false,
                list: [],
                model: {},
                code: 200,
            },
            realHeight: 100,
        }
    },
    created() {
        this.remoteMethod()
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
    },
    methods: {
        remoteMethod(keyword) {
            this.canvas.loading = true
            this.$api.post(this.pageApi, {
                params: {
                    comCode: this.comCode,
                    compName: keyword ? `%${keyword}%` : undefined,
                },
                page: { pageNo: 1, pageSize: 20 }
            })
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.canvas.list = res.data.records.map(item => {
                            item.id = item.comCode + item.compCode
                            return item
                        })

                        if (!this.canvas.model.id && this.canvas.list.length !== 0) {
                            this.canvas.model = this.canvas.list[0]
                            this.showProduct()
                        }
                    } else {
                        this.canvas.message = true
                    }
                })
                .finally(() => {
                    this.canvas.loading = false
                })
        },
        showProduct() {
            this.canvas.message = ''
            let arr = []
            this.$variable.years.forEach(item => {
                const obj = {
                    comName: this.canvas.model.comName,
                    compName: this.canvas.model.compName,
                    amount: this.canvas.model['amount' + item.value],
                    rate: this.$help.parseFloat100(this.canvas.model['rate' + item.value]),
                    year: item.value,
                }
                arr.push(obj)
            })
            this.$help.renderBar(this.nameKey, arr)
        },
        exportInfo(txt = '确定导出吗') {
            const search = this.getParam()
            delete search.sort
            delete search.page
            this.$help.exportInfo(txt, "drug/saleYear/exportSumAmountAndRateGroupByCompNameAndComNameByComName", search)
        },
    }
}
</script>